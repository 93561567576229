import { mdiVectorPolygon, mdiVectorPolyline, mdiMapMarkerOutline, mdiNavigationVariantOutline, mdiCropSquare } from '@mdi/js';
const cartographyConfig= {
    MEASURING_UNITS: {
        POLYGON:[
            { label: 'square feet', value: 'ft2', nickName: "sq ft" },
            { label: 'square yards', value: 'yd2', nickName: "sq yd" },
            { label: 'acres', value: 'a', nickName: "acres" },
            { label: 'square inches', value: 'in2', nickName: "sq in" },
            { label: 'square meters', value: 'm2', nickName: "sq meter", default: true },
            { label: 'hectares', value: 'ha', nickName: "hect" },
            { label: 'square kilometers', value: 'km2', nickName: "sq km" },
        ],
        POLYLINE: [
            { label: 'meter', value: 'm', nickName: "meter", default: true },
            { label: 'kilometers', value: 'km', nickName: "km" },
            { label: 'centimeters', value: 'cm', nickName: "cm" },
            { label: 'millimeters', value: 'mm', nickName: "mm" },
            { label: 'miles', value: 'mi', nickName: "miles" },
            { label: 'seamiles', value: 'sm', nickName: "sm" },
            { label: 'feet', value: 'ft', nickName: "ft" },
            { label: 'inches', value: 'in', nickName: "in" },
            { label: 'yards', value: 'yd', nickName: "yd" },
        ],
        SPOT: [
            { label: "", value: '', disabled: 'disabled', default: true },
        ]
    },
    LABEL_FONTS:[
        {label:"0.5pts" , value:0.5},  
        {label:"0.8pts" , value:0.8},
        {label:"1pts" , value:1},
        {label:"1.3pts" , value:1.3},
        {label:"1.5pts" , value:1.5},
        {label:"1.8pts" , value:1.8},
        {label:"2pts" , value:2},
    ],
    CUSTOM_MEAS_UNITS: [
        { label: "Unit", value: "unit", nickName: "unit" },
        { label: "Each", value: "each", nickName: "each" }
    ],
    CONVERSION_VALUES: {
        KM: 0.001,
        CM: 100,
        MM: 1000,
        MI: 0.000621371,
        SM: 0.000539957,
        FT: 3.28084,
        IN: 39.3701,
        YD: 1.09361,
        M: 1,
        A: 0.000247105, //acres
        IN2: 1550, //square inches
        YD2: 1.19599, //square yards
        FT2: 10.7639, //square ft
        KM2: 1e-6, //square km
        HA: 1e-4,//ha
        M2: 1, //sqaure meter
        UNIT: 1,
        EACH: 1
    },
    MEDIA_TYPES: [{ ext: "mov", type: "video" }, { ext: "mp4", type: "video" }, { ext: "jpg", type: "img" }, { ext: "jpeg", type: "img" }, { ext: "png", type: "img" }],
    RATING: [
        { label: 'Liability', icon: '⛔' , value: 1  },
        { label: 'Immediate Repair', icon: '⚠️' , value: 2  },
        { label: 'Needs Maintenance', icon: '🔧' , value: 3 },
        { label: 'Continue Monitoring', icon: '👁️' ,value: 4 },
        { label: 'Good Condition', icon: '🔋' , value: 5 }
    ],
    SHAPES: { polygon: { label: "Area", icon: mdiVectorPolygon }, polyline: { label: "Line", icon: mdiVectorPolyline }, spot: { label: "Spot", icon: mdiMapMarkerOutline } },
    DRAWING_SIDEBAR: {
        currentLocation: { icon: mdiNavigationVariantOutline, tooltipId: 'mapRecenterTooltip', name: 'Current Location', position: 'right' },
        colorPicker: { icon: null, tooltipId: 'colorPickerTooltip', name: 'Stroke Color', position: 'right' },
        fillColor: { icon: mdiCropSquare, tooltipId: 'colorFillTooltip', name: 'Fill Color', position: 'right' }
    },
    ALPHABETS: 'abcdefghijklmnopqrstuvwxyz',
    MAP_OPTION: [
        {label:"Standard" , value:"standard"},  
        {label:"Satellite" , value:"satellite"},
        {label:"Hybrid" , value:"hybrid"},
        {label:"Terrain" , value:"terrain"},
    ],
    UPLOADING_STATUS: { 1: "Ready to upload", 2: "Uploading...", 3: "Uploaded", 4: "Uploading failed!" },
    PASER_RATING: [
        { label: 'None', value: 0 },
        { label: '1 (Failed)', value: 1 },
        { label: '2 (Very Poor)', value: 2 },
        { label: '3 (Poor)', value: 3 },
        { label: '4 (Fair)', value: 4 },
        { label: '5 (Fair)', value: 5 },
        { label: '6 (Good)', value: 6 },
        { label: '7 (Good)', value: 7 },
        { label: '8 (Very Good)', value: 8 },
        { label: '9 (Excellent)', value: 9 },
        { label: '10 (Excellent)', value: 10 },
    ],
    TREATMENTMEASURE :[
        {label: 'New construction.', value: 10},
        {label: 'Recent overlay. Like new.', value: 9},
        {label: 'Recent sealcoat or new cold mix Little or no maintenance required.', value: 8},
        {label: 'First signs of aging. Maintain with routine crack filling.', value: 7},
        {label: 'Shows signs of aging. Sound structural condition. Could extend life with sealcoat.', value: 6},
        {label: 'Surface aging. Sound structural condition. Needs sealcoat or thin non-structural overlay (less than 2")', value: 5},
        {label: 'Significant aging and first signs of need for strengthening. Would benefit from a structural overlay (2" or more)', value: 4},
        {label: 'Needs patching and repair prior to major overlay. Milling and removal of deterioration extends the life of overlay.', value: 3},
        {label: 'Severe deterioration. Needs reconstruction with extensive base repair. Pulvenzation of old pavement is effective', value: 2},
        {label: 'Failed. Needs total reconstruction.', value: 1},
        {label: 'None', value: 0},
    ],

    PACERVISIBLEDISTRESS :[
        {label: "None", value: 10},
        {label: "None", value: 9},
        {label: "No longitudinal cracks except reflection of paving joints. Occasional transverse cracks, widely spaced (40' or greater). All cracks sealed or tight (open less than 1/4\u0022)", value: 8},
        {label: "Very slight or no raveling, surface shows some traffic wear. Longitudinal cracks (open 1/4\u0022) due to reflection or paving joints Transverse cracks (open 1/4\u0022) spaced 10' or more apart, little or slight crack raveling. No patching or very few patches in excellent condition.", value: 7},
        {label: "Slight raveling (loss of fines) and traffic wear. Longitudinal cracks (open 1/4\u0022 - 1/2\u0022) Transverse cracks (open 1/4\u0022 - 1/2\u0022) some spaced less than 10\u0022. First sign of block crackingto moderate flushing or polishing Occasional patching in good condition.", value: 6},
        {label: "Moderate to severe raveling (loss of fine and coarse aggregate) Longitudinal and transverse cracks (open 1/2\u0022 or more) show first signs of slight raveling and secondary cracks. First signs of longitudinal cracks near pavement edge. Block cracking up to 50% of surface. Extensive to severe flushing or polishing Some patching or edge wedging in good condition.", value: 5},
        {label: "Severe surface raveling. Multiple longitudinal and transverse cracking with slight raveling. Longitudinal cracking in wheel path. Block cracking (over 50% of surface). Patching in fair condition. Slight rutting or distortions (1/2\u0022 deep or less)", value: 4},
        {label: "Closely spaced longitudinal and transverse cracks often showing raveling and crack erosion. Severe block cracking. Sorne alligator cracking (less than 25% of surface). Patches fair to poor condition. Moderate rutting or distortion (greater than 1/2 but less than 2\u0022 deep). Occasional potholes", value: 3},
        {label: "Alligator cracking (over 25% of surface). Severe rutting or distortions (2\u0022 or more deep). Extensive patching in poor condition Potholes.", value: 2},
        {label: "Severe distress with extensive loss of surface integrity.", value: 1},
        {label: 'None', value: 0},
    ],
}

export default cartographyConfig